"use client";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { setCartItemQuantity } from "@/lib/centra/selectionFunctions";
import usePersistedStore from "@/lib/stateManagement/persistedState/persistedStore";

import optimisticQuantityUpdate from "../utils/optimisticUpdate";

interface IItemToRemove {
  line: string;
  numberOfItemsToRemove?: number;
  totalProductQuantity?: number;
}

const getNewQuantity = (variables: IItemToRemove) => {
  const { numberOfItemsToRemove = 0, totalProductQuantity = 1 } = variables;
  const newQuantity = Math.max(0, totalProductQuantity - numberOfItemsToRemove);

  return newQuantity;
};

export default function useRemoveFromCart() {
  const queryClient = useQueryClient();
  const customerToken = usePersistedStore((state) => state.token);

  const removeItems = useMutation({
    mutationFn: async (itemsToRemove: IItemToRemove[]) => {
      let lastResult;
      for (const item of itemsToRemove) {
        lastResult = await setCartItemQuantity(
          {
            line: item.line,
            newQuantity: getNewQuantity(item),
          },
          customerToken
        );
      }

      return lastResult;
    },

    onMutate: async (itemsToRemove) => {
      if (window.CentraCheckout) window.CentraCheckout.resume();
      await queryClient.cancelQueries({ queryKey: ["selection"] });

      const oldData = queryClient.getQueryData(["selection"]);

      queryClient.setQueryData(["selection"], (oldCart: any) => {
        const updatedSelection = itemsToRemove.reduce((updatedCart, item) => {
          const {
            line,
            totalProductQuantity = 1,
            numberOfItemsToRemove = 0,
          } = item;
          const newQuantity = totalProductQuantity - numberOfItemsToRemove;

          const partialResult = optimisticQuantityUpdate(updatedCart, {
            line: line,
            newQuantity: newQuantity,
          });

          return partialResult;
        }, oldCart);

        return updatedSelection;
      });

      return { oldData };
    },

    onError: (_error, _variables, context) => {
      if (context?.oldData) {
        queryClient.setQueryData(["selection"], context?.oldData);
      }
    },

    onSettled: () => {
      if (window.CentraCheckout) window.CentraCheckout.resume();

      queryClient.invalidateQueries({ queryKey: ["selection"] });
    },
  });
  return { removeItems };
}

export type RemoveFromCart = ReturnType<
  typeof useRemoveFromCart
>["removeItems"];
