import { ICartItem } from "@/lib/centra/formatters/types";
import {
  getPatchOptionsAction,
  getPatchProduct,
  getPatchVariantsAction,
} from "@/lib/patch/actions";
import { getPatchVariantFromLetter } from "@/lib/patch/utils.client";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useAddCustomization } from "../../useCustomization/useCustomization";
import usePriceList from "./usePriceList";

export const usePatchProduct = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["patch"],
    queryFn: async () => {
      const res = await fetch("/api/patch/price", {
        method: "GET",
      });

      const product = await res.json();
      return product.product as ReturnType<typeof getPatchProduct>;
    },
    refetchOnMount: false,
  });

  const price = usePriceList(data?.data?.prices);

  return {
    patchProduct: data,
    isLoading,
    price,
  };
};

export const usePatchVariants = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["patch-variants"],
    queryFn: async () => {
      const res = await fetch("/api/patch/variants", {
        method: "GET",
      });

      const variants = await res.json();
      return variants.variants as ReturnType<typeof getPatchVariantsAction>;
    },
    refetchOnMount: false,
  });
  return {
    variants: data,
    isLoading,
  };
};

export const usePatchOptions = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["patch-options"],
    queryFn: async () => {
      const res = await fetch("/api/patch/options", {
        method: "GET",
      });

      const options = await res.json();

      return options.options as ReturnType<typeof getPatchOptionsAction>;
    },
    refetchOnMount: false,
  });

  return {
    options: data,
    isLoading,
  };
};

interface IAddPatchProps {
  patch: string;
  onSuccess?: () => void;
  itemData: ICartItem;
}

export const useAddPatch = () => {
  const addPatchMutation = useAddPatchMutation();

  const addPatch = ({ patch, itemData, onSuccess }: IAddPatchProps) =>
    addPatchMutation.addPatch({
      cartItem: itemData,
      patch,
    });

  return {
    addPatch,
    isLoading: addPatchMutation.isLoading,
  };
};

function useAddPatchMutation() {
  const queryClient = useQueryClient();
  if (!queryClient) console.error("No queryClient");

  const { addCustomization, isLoading } = useAddCustomization();
  const { variants } = usePatchVariants();

  const addPatch = ({
    cartItem,
    patch,
  }: {
    cartItem: ICartItem;
    patch: string;
  }) => {
    const chosenPatchVariant = getPatchVariantFromLetter(variants, patch);
    const itemId = chosenPatchVariant?.sizes?.[0]?.itemId; // patches are always one size

    return addCustomization({
      customizationItems: [{ customizationItemId: itemId ?? "unknown" }],
      cartItem,
    });
  };

  return {
    addPatch,
    isLoading,
  };
}
