import env from "@/env.mjs";
import { ICartItem } from "../centra/formatters/types";

export type CustomizationType = "patch" | "engraving" | "unknown";

export type AllowedCustomizations = Exclude<CustomizationType, "unknown">;

export type CustomizationRecord = Partial<
  Record<AllowedCustomizations, ICartItem>
>;

export function getCustomizationTypeFromItem(
  category: string,
  itemId: string
): CustomizationType {
  if (category === env.NEXT_PUBLIC_CENTRA_PATCH_CATEGORY_ID) return "patch";
  if (itemId === env.NEXT_PUBLIC_CENTRA_ENGRAVING_ID) return "engraving";

  return "unknown";
}

export function isCustomizationItem(category: string, itemId: string): boolean {
  return getCustomizationTypeFromItem(category, itemId) !== "unknown";
}
