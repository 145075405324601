import { serverFetchPCById } from "@/app/serverFunctionsCentra";
import env from "@/env.mjs";
import { useQuery } from "@tanstack/react-query";
import usePriceList from "./usePriceList";

// Need only "453" from "453-2932"
const ENGRAVING_ID = env.NEXT_PUBLIC_CENTRA_ENGRAVING_ID.split("-")[0];

export const useEngravingQuery = () => {
  return useQuery({
    queryKey: ["engraving"],
    queryFn: async () => {
      const res = await fetch("/api/engraving/price", {
        method: "GET",
      });

      const product = await res.json();
      return product.product as ReturnType<typeof serverFetchPCById>;
    },
    refetchOnMount: false,
  });
};
export const useEngravingPrice = () => {
  const { data } = useEngravingQuery();

  const engravingPrices = data?.description?.price;
  const { price: engravingPrice } = usePriceList(engravingPrices);

  return engravingPrice;
};
