"use client";

import { CartItemEngraving } from "@/components/molecules/Engraving/CartItemEngraving";
import { CartItemPatch } from "@/components/molecules/Patch/CartItemPatch";
import { ICartItem } from "@/lib/centra/formatters/types";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";

type CustomizationsProps = {
  item: ICartItem;
  isFinalized?: boolean;
};

export function Customizations({ item, isFinalized }: CustomizationsProps) {
  return (
    <ErrorBoundary>
      {(item.engravingEnabled || Boolean(item.engraving)) && (
        <CartItemEngraving item={item} isFinalized={isFinalized} />
      )}
      {(item.patchEnabled || Boolean(item.patch)) && (
        <CartItemPatch item={item} isFinalized={isFinalized} />
      )}
    </ErrorBoundary>
  );
}
