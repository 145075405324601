import type { CentraSelectionItem } from "@frend-digital/centra/schemas/selection/selectionProductSchema";
import type { CentraSelection } from "@frend-digital/centra/schemas/selection/selectionSchema";

import env from "@/env.mjs";
import { GiftDetailsSchema } from "@/lib/checkout/GiftWrapping/schemas";
import {
  customizationItemSchema,
  customizedItemCommentSchema,
} from "@/lib/customization/schemas";
import {
  CustomizationType,
  getCustomizationTypeFromItem,
  isCustomizationItem,
} from "@/lib/customization/utils.client";
import {
  generateCartSummary,
  getFirstImage,
  getSelectionItemCategory,
  getSelectionItemCategoryName,
  getSelectionItemId,
  getSelectionItemLine,
  getSelectionItemName,
  getSelectionItemPriceEach,
  getSelectionItemPriceEachAsNumber,
  getSelectionItemPriceEachBeforeDiscount,
  getSelectionItemQuantity,
  getSelectionItemSize,
  getSelectionItemSku,
  getSelectionItemUri,
  getSelectionItemVariantName,
  getSelectionProductId,
} from "../selectionAtoms";
import { ICartItem } from "./types";

const generateCartItem = (item: CentraSelectionItem) => {
  const itemId = getSelectionItemId(item) ?? "";
  const name = getSelectionItemName(item) ?? "";
  const uri = getSelectionItemUri(item) ?? "";
  const variant = getSelectionItemVariantName(item) ?? "";
  const sku = getSelectionItemSku(item) ?? "";
  const size = getSelectionItemSize(item) ?? "";
  const shape = item?.product?.pr_shape;
  const series = item?.product?.pr_series;
  const engravingEnabled = item?.product?.pr_engraving_key !== "1";
  const patchEnabled = item?.product?.pr_patch_key === "1";
  const priceAsNumber = getSelectionItemPriceEachAsNumber(item) ?? 0;
  const price = getSelectionItemPriceEach(item) ?? "";
  const discountedPrice = getSelectionItemPriceEachBeforeDiscount(item) ?? "";
  const quantity = getSelectionItemQuantity(item) ?? 0;
  const category = getSelectionItemCategory(item) ?? "";
  const categoryName = getSelectionItemCategoryName(item) ?? "";
  const productId = getSelectionProductId(item) ?? "";
  const comment = item.comment;
  // const media = getFirstImage(item);
  const media = {
    standard: getFirstImage(item, "standard"),
    full: getFirstImage(item, "full"),
  };

  // TODO: why do we need both line and id if they have the same value?
  const id = getSelectionItemLine(item) ?? "";
  const line = item.line ?? "";

  return {
    name,
    series,
    shape,
    uri,
    variant,
    size,
    id,
    price,
    quantity,
    media,
    priceAsNumber,
    discountedPrice,
    itemId,
    line,
    sku,
    category,
    categoryName,
    productId,
    engravingEnabled,
    patchEnabled,
    comment,
  };
};

const groupProductsWithCustomizations = (products: ICartItem[]) => {
  return products.map((cartItem) => {
    const customization = customizedItemCommentSchema.safeParse(
      JSON.parse(cartItem.comment ?? "{}")
    );
    if (!customization.success) return cartItem;

    if (isCustomizationItem(cartItem.category, cartItem.itemId)) {
      return cartItem;
    }

    // Find matching customization items
    const customizationItems = products.filter((product) => {
      const customizationItem = customizationItemSchema.safeParse(
        JSON.parse(product.comment ?? "{}")
      );

      const isCustomization = isCustomizationItem(
        product.category,
        product.itemId
      );
      return (
        isCustomization &&
        customizationItem.success &&
        customizationItem.data.comment.item === customization.data.comment.item
      );
    });

    return {
      ...cartItem,
      customizationItems: customizationItems,
    };
  });
};

type FormatterCallback<T> = (items: T[]) => T[];

interface IFormatter<T> {
  condition: (item: T, allItems: T[]) => boolean;
  transform: (item: T, allItems: T[]) => T;
}

const createFormatter = <T>(formatter: IFormatter<T>): FormatterCallback<T> => {
  return (items: T[]): T[] => {
    try {
      return items.map((item) =>
        formatter.condition(item, items)
          ? formatter.transform(item, items)
          : item
      );
    } catch (error) {
      console.error("Error in formatter:", error);
      return items;
    }
  };
};

/* add new formatters here - order matters */
const formatters: IFormatter<ICartItem>[] = [
  /* gift wrapping item */
  {
    condition: (product) =>
      product.category === env.NEXT_PUBLIC_CENTRA_GIFT_CATEGORY_ID &&
      !!product.comment,
    transform: (product) => {
      if (!product.comment) return product;
      const parsed = GiftDetailsSchema.safeParse(JSON.parse(product.comment));
      return parsed.success
        ? { ...product, giftWrapping: parsed.data.comment.txt }
        : product;
    },
  },
  {
    condition: (item) =>
      Boolean(item.customizationItems) && item.customizationItems!.length > 0,
    transform: (item) => {
      const customizations: Record<CustomizationType, ICartItem> = {} as Record<
        CustomizationType,
        ICartItem
      >;
      for (const customizationItem of item.customizationItems!) {
        const type = getCustomizationTypeFromItem(
          customizationItem.category,
          customizationItem.itemId
        );
        customizations[type] = customizationItem;
      }

      return {
        ...item,
        ...customizations,
      };
    },
  },
];

const formattingFunctions = formatters.map(createFormatter);

const composeFormatters = <T>(
  ...formatters: FormatterCallback<T>[]
): FormatterCallback<T> => {
  return (items: T[]): T[] =>
    formatters.reduce((result, formatter) => formatter(result), items);
};

const composedFormatter = composeFormatters(...formattingFunctions);

export const formatItems = (items: CentraSelectionItem[]) => {
  const cartItems = items.map((item) => generateCartItem(item));

  const productsGroupedByCustomizations =
    groupProductsWithCustomizations(cartItems);

  const formattedItems = composedFormatter(productsGroupedByCustomizations);

  /* filter out customizations */
  const filteredItems = formattedItems.filter((item) => {
    return !isCustomizationItem(item.category, item.itemId);
  });

  return filteredItems;
};

export const formatSelection = (data: CentraSelection) => {
  const items = data.selection?.items ?? [];

  const products = formatItems(items);

  const generatedSummary = generateCartSummary(data);

  const customizations = data.selection?.items?.filter((item) =>
    isCustomizationItem(item.product?.category, item.item!)
  );

  const summary = {
    ...generatedSummary,
    totalQuantity: data.selection?.totals?.totalQuantity
      ? data.selection?.totals?.totalQuantity -
        (customizations?.length ? customizations.length : 0)
      : 0,
  };

  const formattedData = {
    ...data,
    selection: {
      ...data.selection,
      items: products,
      products,
      summary,
    },
    // Keep original selection for optimistic update changes
    rawSelection: data.selection,
  };

  return formattedData;
};

export type FormattedSelection = ReturnType<typeof formatSelection>;

export default formatSelection;
