"use client";

import { Button } from "@/components/atoms";
import Input from "@/components/molecules/NewForm/Input";
import { useValidation } from "@/lib/hooks/useValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslations } from "next-intl";
import { FormProvider, useForm } from "react-hook-form";

export interface IEngravingFormProps {
  buttonLabel?: string;
  handleAddEngraving: (formData: { engraving: string }) => void;
}

export const EngravingForm = ({
  buttonLabel,
  handleAddEngraving,
}: IEngravingFormProps) => {
  const t = useTranslations();
  const validation = useValidation();

  const schema = validation
    .object({ engraving: validation.string().max(4).required() })
    .required();

  const defaultValues = {
    engraving: "",
  };

  const form = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { handleSubmit } = form;

  const onSubmit = handleSubmit((formData) => {
    handleAddEngraving(formData);
  });

  return (
    <div style={{ width: "100%" }}>
      <FormProvider {...form}>
        <form onSubmit={onSubmit}>
          <Input
            name="engraving"
            placeholder={t("components.fields.engraving.placeholder")}
          />

          <Button type="submit" full>
            {buttonLabel}
          </Button>
        </form>
      </FormProvider>
    </div>
  );
};
