"use client";

import env from "@/env.mjs";
import { ICartItem } from "@/lib/centra/formatters/types";
import { customizationItemSchema } from "@/lib/customization/schemas";
import {
  useAddCustomization,
  useRemoveCustomization,
} from "@/lib/hooks/useCustomization/useCustomization";
import { useEngravingPrice } from "@/lib/hooks/useSelection/cart/useEngravingPrice";
import { useTranslations } from "next-intl";
import { useState } from "react";
import { EngravingForm } from "./EngravingForm";
import { EngravingStatus } from "./EngravingStatus";
import { ToggleEngravingFormButton } from "./ToggleEngravingFormButton";
import styles from "./engraving.module.css";

export interface IEngravingProps {
  item: ICartItem;
  isFinalized?: boolean;
}

export const CartItemEngraving = ({ item, isFinalized }: IEngravingProps) => {
  const t = useTranslations();
  const [isEngravingFormVisible, setIsEngravingFormVisible] = useState(false);
  const removeCustomization = useRemoveCustomization();
  const { addCustomization, isLoading: isLoadingCustomization } =
    useAddCustomization();

  const engravingItem = item?.engraving;
  const parsed = customizationItemSchema.safeParse(
    JSON.parse(engravingItem?.comment ?? "{}")
  );
  const comment = parsed.success ? parsed.data : undefined;
  const details = comment?.comment.txt;

  const engravingPrice = useEngravingPrice();

  const handleRemoveEngraving = () => {
    removeCustomization.removeCustomization({
      orderLineId: item.engraving?.line ?? "",
    });
  };

  return (
    <div className={styles.engraving}>
      {!isFinalized && (
        <>
          {!details && (
            <ToggleEngravingFormButton
              setIsEngravingFieldVisible={setIsEngravingFormVisible}
              isEngravingFieldVisible={isEngravingFormVisible}
              price={engravingPrice}
            />
          )}

          {isEngravingFormVisible && (
            <EngravingForm
              handleAddEngraving={({ engraving }) =>
                addCustomization({
                  customizationItems: [
                    {
                      customizationItemId: env.NEXT_PUBLIC_CENTRA_ENGRAVING_ID,
                      customizationItemComment: engraving,
                    },
                  ],
                  cartItem: item,
                  onSuccess: () => setIsEngravingFormVisible(false),
                })
              }
              buttonLabel={t("common.add")}
            />
          )}
        </>
      )}

      {details && (
        <EngravingStatus
          engraving={details}
          removeEngraving={isFinalized ? undefined : handleRemoveEngraving}
        />
      )}
    </div>
  );
};
