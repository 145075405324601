"use client";

import { Button } from "@/components/atoms";
import Plus from "@/icons/Plus";
import { ICartItem } from "@/lib/centra/formatters/types";
import {
  useAddCustomization,
  useRemoveCustomization,
} from "@/lib/hooks/useCustomization/useCustomization";
import {
  usePatchOptions,
  usePatchProduct,
  usePatchVariants,
} from "@/lib/hooks/useSelection/cart/patch";
import { getPatchVariantFromLetter } from "@/lib/patch/utils.client";
import clsx from "clsx";
import { useTranslations } from "next-intl";
import { useState } from "react";
import styles from "./patch.module.css";
import { PatchForm } from "./PatchForm";
import { PatchStatus } from "./PatchStatus";

export interface IPatchProps {
  item: ICartItem;
  isFinalized?: boolean;
}

export const CartItemPatch = ({ item, isFinalized }: IPatchProps) => {
  const t = useTranslations();
  const [isPatchFormVisible, setIsPatchFormVisible] = useState(false);
  const { options } = usePatchOptions();
  const { variants } = usePatchVariants();
  const removeCustomization = useRemoveCustomization();
  const { addCustomization, isLoading: customizationIsLoading } =
    useAddCustomization();

  const { price } = usePatchProduct();

  const handleRemovePatch = () => {
    removeCustomization.removeCustomization({
      orderLineId: item.patch?.line ?? "",
    });
  };

  return (
    <div className={styles.engraving}>
      {!isFinalized && (
        <>
          {!item.patch && options && options.length > 0 && (
            <TogglePatchFormButton
              setIsPatchFieldVisible={setIsPatchFormVisible}
              isPatchFieldVisible={
                isPatchFormVisible && !customizationIsLoading
              }
              price={price.price}
            />
          )}

          {isPatchFormVisible && options && (
            <PatchForm
              disabled={customizationIsLoading}
              options={options}
              handleAddPatch={(patch) => {
                const chosenPatchVariant = getPatchVariantFromLetter(
                  variants,
                  patch
                );
                const itemId = chosenPatchVariant?.sizes?.[0]?.itemId; // patches are always one size

                return addCustomization({
                  customizationItems: [
                    { customizationItemId: itemId ?? "unknown" },
                  ],
                  cartItem: item,
                });
              }}
            />
          )}
        </>
      )}

      {item.patch && (
        <PatchStatus
          letter={item.patch.variant}
          item={item.patch}
          removePatch={isFinalized ? undefined : handleRemovePatch}
        />
      )}
    </div>
  );
};

interface ITogglePatchFormButtonProps {
  setIsPatchFieldVisible: (isVisible: boolean) => void;
  isPatchFieldVisible: boolean;
  price: string;
}

export const TogglePatchFormButton = ({
  setIsPatchFieldVisible,
  isPatchFieldVisible,
  price,
}: ITogglePatchFormButtonProps) => {
  const t = useTranslations();

  return (
    <Button
      color="transparent"
      onClick={() => setIsPatchFieldVisible(!isPatchFieldVisible)}
      className={styles.togglePatchButton}
      type="button"
    >
      <span>
        <span>
          {t("components.patch.button.add", {
            price,
          })}
        </span>

        <Plus className={clsx(isPatchFieldVisible && styles.rotate)} />
      </span>
    </Button>
  );
};
