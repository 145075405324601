import type { CentraSelectionItem } from "@frend-digital/centra/schemas/selection/selectionProductSchema";

import type { CentraProduct } from "./atomicSetup";
import { baseAtomics } from "./atomicSetup";
import formatProductCard from "./formatters/formatProductCards/formatProductCard";
import { formatVariant } from "./formatters/formatVariants";
import {
  getSelectionItemLine,
  getSelectionItemName,
  getSelectionItemPriceEach,
  getSelectionItemQuantity,
  getSelectionItemSize,
  getSelectionItemUri,
  getSelectionItemVariantName,
} from "./selectionAtoms";

const {
  getMediaBySize,
  getMeasurementChart,
  getProductBreadcrumbs,
  getProductSizes,
} = baseAtomics;

export const getUsp = (x: 1 | 2 | 3 | 4 | 5) => (product: CentraProduct) => {
  const disUsp = `dis_usp_${x}_text` as const;
  if (product[disUsp]) return product[disUsp];

  return product[`pr_usp_${x}_text`];
};

export const getUsps = (product: CentraProduct) => {
  const usps: string[] = [];

  for (let i = 1; i <= 5; i++) {
    usps.push(getUsp(i as 1 | 2 | 3 | 4 | 5)(product));
  }

  return usps.filter((usp) => !!usp);
};

const formatMeasurementChart = (chart: {
  contents: { x: number; y: number; content: string }[];
  x: string[];
  y: string[];
}) => {
  const { contents, x, y } = chart;
  const formattedChart = [];

  for (let i = 0; i < y.length; i++) {
    const row = { size: y[i] } as {
      size: string;
      [measurement: string]: string;
    };
    for (let j = 0; j < x.length; j++) {
      const measurement = x[j];
      const value = contents.find(
        (content: { x: number; y: number }) =>
          content.x === j && content.y === i
      )?.content;
      if (value) {
        row[measurement] = value;
      }
    }
    formattedChart.push(row);
  }

  return { chart: formattedChart, keys: x };
};

export const getSizeGuide = (product: CentraProduct) => {
  const measurementChart = getMeasurementChart(product);
  const sizeGuide = formatMeasurementChart(measurementChart);
  return sizeGuide;
};

export const getSortOrder = (product: CentraProduct) => {
  return product.dis_order_value
    ? Number(product.dis_order_value)
    : 1000 + product.product;
};

export const getBundle = (product: CentraProduct) => {
  return !!product?.bundleInfo;
};

export const getPDPName = (product: CentraProduct) => {
  return [getSeries(product)?.value, getShape(product)?.value];
};

export const getBundleName = (product: CentraProduct) => {
  return product?.name;
};

export const getBundleData = (product: CentraProduct) => {
  if (!product?.bundleData) {
    return false;
  }
  const bundle = product?.bundleData as any;
  return bundle?.bundle?.bundleInfo;
};

export const getComingSoon = (product: CentraProduct) => {
  const comingSoon = product?.pr_coming_soon_value;

  return comingSoon;
};
export const getEngraving = (product: CentraProduct) => {
  const engravingKey = product.pr_engraving_key;
  if (engravingKey === "1") return false;

  return true;
};

export const getPatchKey = (product: CentraProduct) => {
  const patchKey = product.pr_patch_key;

  if (patchKey === "1") return true;

  return false;
};

export const getSoldOut = (product: CentraProduct) => {
  const sizes = getProductSizes(product);
  const soldOut = sizes?.every((size) => size.stock === false);

  return soldOut;
};

export const getProductKeywords = (product: CentraProduct) => {
  const keywords = product?.dis_meta_keywords;
  return keywords?.split(",").map((keyword) => keyword.trim());
};

export const getSwatch = (product: CentraProduct) => {
  if (product?.bundleData?.bundle?.dis_color) {
    return {
      name: "bundleswatch",
      code: "#ddd",
      image: product.bundleData.bundle.dis_color,
    };
  }
  if (product?.dis_color) {
    return {
      name: "bundleswatch",
      code: "#ddd",
      image: product.dis_color,
    };
  }
  const swatch = product.var_color
    ? product.var_color
    : { name: "fallback", code: "#ddd", image: undefined };
  return swatch;
};

export const getShape = (product: CentraProduct) => {
  const composition = product.pr_shape;
  return composition;
};

export const getSeries = (product: CentraProduct) => {
  return product?.pr_series;
};

export const getCare = (product: CentraProduct) => {
  const care = product.pr_care;
  return care;
};

export const getShipping = (product: CentraProduct) => {
  const shipping = product.dis_shipping;
  return shipping;
};

export const getLabels = (product: CentraProduct) => {
  const labels = product.var_labels;
  return labels;
};

export const getFitAdvice = (product: CentraProduct) => {
  const fit = product.pr_fit_advice;
  return fit;
};

export const getFaq = (product: CentraProduct) => {
  const faq = product.pr_faq;
  return faq;
};

export const getShortDesc = (product: CentraProduct) => {
  if (product.dis_short_desc_web_text) return product.dis_short_desc_web_text;

  if (product.excerpt) return product.excerpt;

  return product.pr_description_value;
};

export const getLongDesc = (product: CentraProduct) => {
  if (product.dis_long_desc_web_text) return product.dis_long_desc_web_text;

  if (product.description) return product.description;

  return product.pr_long_description_value;
};

export const getFabricProps = (product: CentraProduct) => {
  const fabricProps = product.pr_fabric_props;
  return fabricProps;
};

export const getMedia = (product: CentraProduct) => {
  const media = product.mediaObjects?.map((mediaObject) => ({
    type: "image",
    href: mediaObject.sources.full[0].url,
    alt: mediaObject.attributes.img_config_alt_text || "",
    test: mediaObject.attributes.img_config_alt_text,
  }));
  return media;
};

const getRelatedProducts = (product: CentraProduct) => {
  if (product?.bundleData) {
    const bundle = product?.bundleData as any;
    return bundle?.bundle?.relatedProducts;
  }
  return product.relatedProducts;
};
export const getSpecs = (product: CentraProduct) => {
  return {
    name: product.name,
    sku: product.sku.slice(product.productSku.length, product.sku.length),
    collection: product.collection,
    variant: product.variantName,
    series: product.pr_series,
    shape: product.pr_shape,
    color: product.var_color,
    env: product.var_env_impact_text,
    strap: product.var_strap_material_value,
    origin: product.countryOrigin,
    originStrap: product.var_strap_material_cog,
    emm: product.var_exterior_main_materials_value,
    emmCog: product.var_exterior_main_materials_cog,
    emmSupplier: product.var_exterior_main_materials_supplier,
    emmRawCog: product.var_exterior_main_materials_raw_cog,
    imm: product.var_interior_main_materials_value,
  };
};
export const getVariants = (product: CentraProduct) => {
  const relatedProducts = getRelatedProducts(product) as CentraProduct[];
  if (Array.isArray(relatedProducts)) {
    const inactiveVariants = relatedProducts
      ?.filter((product) => product.relation === "variant")
      .map((product) => {
        return {
          ...formatVariant(product).data,
          selected: false,
        };
      });

    const activeVariant = { ...formatVariant(product).data, selected: true };
    let variants = inactiveVariants
      ? [activeVariant, ...inactiveVariants]
      : [activeVariant];
    variants = variants.filter((variant) => {
      return product.dis_shipping === variant.shipping;
    });
    return variants.sort((a, b) => {
      if ((a.sortOrder || 1000) > (b.sortOrder || 1000)) {
        return 1;
      }
      if ((a.sortOrder || 1000) < (b.sortOrder || 1000)) {
        return -1;
      }
      return 0;
    });
  }
};

export const getRelatedProductCards = (product: CentraProduct) => {
  const data = getRelatedProducts(product) as CentraProduct[];
  if (!Array.isArray(data)) return [];
  const relatedProducts = data?.filter(
    (product) => product.relation !== "variant"
  );
  return relatedProducts.map((product) => formatProductCard(product));
};
export type RelatedProductCards = ReturnType<typeof getRelatedProductCards>;

export const getFirstImage = (
  item: CentraSelectionItem
): {
  standard: {
    href: string;
    alt: string;
  };
  full: {
    href: string;
    alt: string;
  };
} => {
  const mediaData = Object.keys(item?.product?.media);

  let media = {} as {
    standard: {
      href: string;
      alt: string;
    };
    full: {
      href: string;
      alt: string;
    };
  };
  mediaData.forEach((key) => {
    if (key === "standard") {
      media.standard = { href: item?.product?.media[key]?.[0], alt: "" };
    }
    if (key === "full") {
      media.full = { href: item?.product?.media[key]?.[0], alt: "" };
    }
  });
  return media;
};

export const generateCartItem = (item: CentraSelectionItem) => {
  const name = getSelectionItemName(item) ?? "";
  const uri = getSelectionItemUri(item) ?? "";
  const variant = getSelectionItemVariantName(item) ?? "";
  const size = getSelectionItemSize(item) ?? "";
  const id = getSelectionItemLine(item) ?? "";
  const price = getSelectionItemPriceEach(item);
  const discountedPrice = getSelectionItemPriceEach(item);
  const quantity = getSelectionItemQuantity(item) ?? 0;
  const media = getFirstImage(item);
  const line = item.line ?? "";
  return {
    name,
    uri,
    variant,
    size,
    id,
    price,
    quantity,
    media,
    discountedPrice,
    line,
  };
};

export type GeneratedCartItem = ReturnType<typeof generateCartItem>;

export const getStandardRelatedProducts = (item: CentraProduct) => {
  const source = getRelatedProducts(item) as CentraProduct[];
  const standardRelatedProducts = (source as CentraProduct[]).filter(
    (product) => product.relation === "standard"
  );
  return standardRelatedProducts.map((product) => formatProductCard(product));
};

export const getIsNew = (item: CentraProduct) => {
  if ("pr_new" in item) return item.pr_new as "1";
  else return;
};
