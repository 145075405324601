import { usePatchVariants } from "../hooks/useSelection/cart/patch";

/**
 * Given a list of variants, find the variant that matches the given letter
 * patches are derived from the variant name, ex AA-WHITE, where the letters are AA.
 */
export function getPatchVariantFromLetter(
  variants: ReturnType<typeof usePatchVariants>["variants"],
  variantName: string
) {
  return variants?.find((variant) => {
    const letterFromVariant = variant?.variantName;
    return letterFromVariant === variantName;
  });
}
