"use client";
import Button from "@/components/atoms/Button";
import { usePatchProduct } from "@/lib/hooks/useSelection/cart/patch";
import React from "react";
import styles from "./patch.module.css";

import Dropdown from "@/lib/checkout/Dropdown";
import { PatchOption } from "@/lib/patch/schemas";
import { useTranslations } from "next-intl";
import type { OnChangeValue } from "react-select";

type PatchFormProps = {
  options: PatchOption[];
  handleAddPatch: (patch: string) => void;
  disabled?: boolean;
};

export const PatchForm = ({
  options,
  handleAddPatch,
  disabled,
}: PatchFormProps) => {
  const [selectedChar, setSelectedChar] = React.useState("");
  const { price } = usePatchProduct();
  const [error, setError] = React.useState<boolean>(false);
  const t = useTranslations();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedChar) {
      setError(true);
      return;
    }

    handleAddPatch(selectedChar);
  };

  const handleChange = (newValue: OnChangeValue<any, false>) => {
    if (newValue) {
      setSelectedChar((newValue as any).value);
      setError(false);
    }
  };

  if (!options || options.length === 0 || !price) return null;

  const selectOptions = [
    ...options.map((option) => {
      const inStock = option.inStock;

      return {
        label: inStock
          ? option.label
          : `${option.label} - ${t("components.patch.soldout")}`,
        value: option.value,
        disabled: !option.inStock,
      };
    }),
  ];

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <div className={styles.selectWrapper}>
        <Dropdown
          options={selectOptions}
          onChange={handleChange}
          value={selectedChar}
          disableSearch
          placeholder={t("components.patch.placeholder")}
        />
      </div>
      {error && <p>{t("common.validation.required")}</p>}
      <Button type="submit">
        {t("components.patch.button.add", {
          price: price.price,
        })}
      </Button>
    </form>
  );
};
