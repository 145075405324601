"use client";

import Close from "@/icons/Close";
import { ICartItem } from "@/lib/centra/formatters/types";
import {
  usePatchProduct,
  usePatchVariants,
} from "@/lib/hooks/useSelection/cart/patch";
import clsx from "clsx";
import { useTranslations } from "next-intl";
import styles from "./patch.module.css";

type PatchStatusProps = {
  item?: ICartItem;
  letter: string;
  removePatch?: () => void;
  className?: string;
};

export const PatchStatus = ({
  item,
  letter,
  removePatch,
  className,
}: PatchStatusProps) => {
  const t = useTranslations();
  const { variants } = usePatchVariants();
  const { price } = usePatchProduct();

  if (letter === "") return null;
  if (!variants) return null;

  return (
    <div className={clsx(styles.patchStatus, className)}>
      <div>
        {t("components.patch.text", { patch: letter })}

        {removePatch && (
          <button onClick={removePatch} className={styles.removePatchButton}>
            <Close />
          </button>
        )}
      </div>

      <div>+{price.price}</div>
    </div>
  );
};
