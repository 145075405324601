import { z } from "zod";

/* customization comment schemas (comment: txt: details: (customzation comment)) */
export const engravingCustomizationCommentSchema = z.object({
  message: z.string().min(1, "Message is required"),
});

export type EngravingCustomizationComment = z.infer<
  typeof engravingCustomizationCommentSchema
>;

export const customizationCommentDetails = z.union([
  engravingCustomizationCommentSchema,
  z.undefined(),
]);
export type CustomizationCommentDetails = z.infer<
  typeof customizationCommentDetails
>;

/* general schemas */
export const customizationItemSchema = z.object({
  comment: z.object({
    txt: z.string().optional(),
    item: z.string().min(1, "Customization item id is required"),
  }),
});

export type CustomizationItemComment = z.infer<typeof customizationItemSchema>;

export const customizedItemCommentSchema = z.object({
  comment: z.object({
    item: z.string().min(1, "ID is required"),
  }),
});

export type CustomizedItemComment = z.infer<typeof customizedItemCommentSchema>;

export const addCustomizationInputSchema = z.object({
  customizationItems: z.array(
    z.object({
      customizationItemId: z
        .string()
        .min(1, "Customization item id is required"),
      customizationItemComment: z.string().optional(),
    })
  ),
  cartItemId: z.string().min(1, "Cart item ID is required"),
  token: z.string().min(1, "Token is required"),
});

export type AddCustomizationInput = z.infer<typeof addCustomizationInputSchema>;

export const removeCustomizationInputSchema = z.object({
  orderLineId: z.string().min(1, "Cart item ID is required"),
  token: z.string().min(1, "Token is required"),
});

export type RemoveCustomizationInput = z.infer<
  typeof removeCustomizationInputSchema
>;

export const purgeCustomizationsForItemInputSchema = z.object({
  item: z.string().min(1, "Customization ID is required"),
  token: z.string().min(1, "Token is required"),
});

export type PurgeCustomizationsForItemInput = z.infer<
  typeof purgeCustomizationsForItemInputSchema
>;

export const validateCartCustomizationsInputSchema = z.object({
  token: z.string().min(1, "Token is required"),
});

export type ValidateCartCustomizationsInput = z.infer<
  typeof validateCartCustomizationsInputSchema
>;
