import { ICartItem } from "@/lib/centra/formatters/types";
import {
  addCustomization,
  purgeCustomizationsForItemAction,
  removeCustomizationAction,
} from "@/lib/customization/actions";
import {
  AddCustomizationInput,
  customizedItemCommentSchema,
  RemoveCustomizationInput,
} from "@/lib/customization/schemas";
import usePersistedStore from "@/lib/stateManagement/persistedState/persistedStore";
import { useMutation, useQueryClient } from "@tanstack/react-query";

type IAddCustomizationProps = {
  customizationItems: AddCustomizationInput["customizationItems"];
  cartItem: ICartItem; // the cart item you want customized
  onSuccess?: () => void;
};

export const useAddCustomization = () => {
  const addCustomizationMutation = useAddCustomizationMutation();
  const addCustomization = ({
    customizationItems,
    cartItem,
    onSuccess,
  }: IAddCustomizationProps) =>
    addCustomizationMutation.mutateAsync(
      {
        cartItem,
        customizationItems,
      },

      { onSuccess }
    );

  return {
    addCustomization,
    isLoading: addCustomizationMutation.isPending,
  };
};

function useAddCustomizationMutation() {
  const queryClient = useQueryClient();
  if (!queryClient) console.error("No queryClient");

  const customerToken = usePersistedStore((state) => state.token);

  const addCustomizationMutation = useMutation({
    mutationFn: (input: IAddCustomizationProps) =>
      postCustomization({
        customizationItems: input.customizationItems,
        cartItemId: input.cartItem.id,
        token: customerToken,
      }),

    onMutate: async (input) => {
      await queryClient.cancelQueries({ queryKey: ["selection"] });

      const oldData = queryClient.getQueryData(["selection"]);

      return { oldData };
    },

    onSuccess: (input) => {
      input &&
        input.value &&
        queryClient.setQueryData(["selection"], input.value);
    },

    onError: () => {
      queryClient.invalidateQueries({
        queryKey: ["selection"],
      });
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["selection"],
      });
    },
  });

  return addCustomizationMutation;
}

const postCustomization = async (input: AddCustomizationInput) => {
  const response = await addCustomization(input);

  if (!response.success) {
    throw new Error("Error adding customization to cart");
  }
  return response.data as any;
};

type IRemoveCustomizationProps = {
  orderLineId: string; // the cart item with the customization
  onSuccess?: () => void;
};

export const useRemoveCustomization = () => {
  const removeCustomizationMutation = useRemoveCustomizationMutation();
  const purgeCustomizationsMutation = usePurgeCustomizationsMutation();

  const removeCustomization = ({
    orderLineId,
    onSuccess,
  }: IRemoveCustomizationProps) =>
    removeCustomizationMutation.mutateAsync(
      {
        orderLineId,
      },
      { onSuccess }
    );

  const purgeCustomizations = ({
    customizedItem,
    onSuccess,
  }: IpurgeCustomizationsProps) =>
    purgeCustomizationsMutation.mutateAsync(
      {
        customizedItem,
      },
      { onSuccess }
    );

  return {
    removeCustomization,
    purgeCustomizations,
    isLoading: removeCustomizationMutation.isPending,
  };
};

function useRemoveCustomizationMutation() {
  const queryClient = useQueryClient();
  if (!queryClient) console.error("No queryClient");

  const customerToken = usePersistedStore((state) => state.token);

  const removeCustomizationMutation = useMutation({
    mutationFn: (input: IRemoveCustomizationProps) =>
      deleteCustomization({
        orderLineId: input.orderLineId,
        token: customerToken,
      }),

    onMutate: async (input) => {
      await queryClient.cancelQueries({ queryKey: ["selection"] });
      const oldData = queryClient.getQueryData(["selection"]);
      return { oldData };
    },

    onSuccess: (input) => {
      input?.value && queryClient.setQueryData(["selection"], input.value);
    },

    onError: () => {
      queryClient.invalidateQueries({
        queryKey: ["selection"],
      });
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["selection"],
      });
    },
  });

  return removeCustomizationMutation;
}

const deleteCustomization = async (input: RemoveCustomizationInput) => {
  const response = await removeCustomization(input);

  if (!response.success) {
    throw new Error("Error removing customization from cart");
  }
  return response.data as any;
};

const removeCustomization = async (input: RemoveCustomizationInput) => {
  await removeCustomizationAction(input);
  return { success: true, data: null };
};

type IpurgeCustomizationsProps = {
  customizedItem: ICartItem;
  onSuccess?: () => void;
};

function usePurgeCustomizationsMutation() {
  const queryClient = useQueryClient();
  if (!queryClient) console.error("No queryClient");

  const customerToken = usePersistedStore((state) => state.token);

  const purgeCustomizationsMutation = useMutation({
    mutationFn: (input: IpurgeCustomizationsProps) =>
      purgeCustomizations({
        item: input.customizedItem,
        token: customerToken,
      }),

    onMutate: async (input) => {
      await queryClient.cancelQueries({ queryKey: ["selection"] });
      const oldData = queryClient.getQueryData(["selection"]);
      return { oldData };
    },

    onSuccess: (input) => {
      input?.data && queryClient.setQueryData(["selection"], input.data);
    },

    onError: () => {
      queryClient.invalidateQueries({
        queryKey: ["selection"],
      });
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["selection"],
      });
    },
  });

  return purgeCustomizationsMutation;
}

const purgeCustomizations = async ({
  item,
  token,
}: {
  item: ICartItem;
  token: string;
}) => {
  try {
    const comment = customizedItemCommentSchema.parse(
      JSON.parse(item.comment ?? "")
    );
    const response = await purgeCustomizationsForItemAction({
      item: comment.comment.item,
      token,
    });

    if (!response.success) {
      throw new Error("Error purging customizations from cart");
    }
    return response.data as any;
  } catch (error) {}
};
